<template>
	<ion-page id="info">
		<div v-show="frameEnabled" v-if="activeCampaign">
			<iframe :src="`../#/categories/${activeCampaign}`"  />
			<ion-backdrop></ion-backdrop>
		</div>
		<ion-content v-if="campaign.description">
			<ion-grid class="bg-image">
        <a-blob :color=" campaign.color3 ?? '#FC7B48'" position="top"/>
        <a-blob :color=" campaign.color4 ?? '#65D7FF'" position="bottom"/>

				<ion-row class="ion-padding notch-margin-top">
					<ion-col size="4">
						<ion-row>
							<img class="icon-header" src="../../assets/icon/arrow-left.png" @click="$router.push({name:'Landing'})">
						</ion-row>
					</ion-col>
					<ion-col size="1">
					</ion-col>
					<ion-col size="2" class="ion-text-right">
						<a href="https://www.facebook.com/beentheretogether">
							<img class="a-social-icon" src="../../assets/svg/facebook-logo.svg">
						</a>
					</ion-col>
					<ion-col size="2" class="ion-text-center">
						<a href="https://www.instagram.com/beentheretogether/">
							<img class="a-social-icon" src="../../assets/svg/instagram-logo.svg">
						</a>
					</ion-col>
					<ion-col size="3" class="align">
						<ion-button color="dark" class="a-play-button" @click="onPlay()">{{ $t("PLAY") }}</ion-button>
					</ion-col>
				</ion-row>
				<ion-row>
					<ion-col class="wrapper" size="12">
						<div class="caption ion-padding-horizontal">
							<h3 class="font-monoxil text-bold">BEEN THERE TOGETHER:</h3>
							<div class="caption" v-html="addBEtoSrc(campaign.full_description)"></div>
						</div>
					</ion-col>
				</ion-row>
			</ion-grid>
		</ion-content>
	</ion-page>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import blob from '@/plugins/app/landing/a-blob.vue'


export default {
  components: {
    'a-blob': blob,
  },
  async mounted() {
    if (!this.activeCampaign)
      this.$router.push({name: 'Campaigns'})

  },
	methods:{
		onPlay(){
			if(window.top.innerWidth >= 1024){
				this.frameEnabled = true
			} else {
				this.$router.push({name: 'Categories'})
			}

		},
    addBEtoSrc(string) {
      return string.split('src=\"').join('src=\"' + process.env.VUE_APP_BE_URL )
    }
	},
	computed: {
		...mapFields('offline', ['frameEnabled', 'campaign', 'activeCampaign']),
	}
}
</script>

<style lang="scss">

#info {
  ion-col {
    padding: 0;
  }

  .icon-header {
    height: 6vw;
    margin-top: 7vw;
  }

  .text-header {
    font-size: 4vw;
    margin: 5vw 2vw;
    line-height: 10vw;
  }

  .align {
    text-align: right;
  }


  .caption {

    h1 {
      font-size: 10.5vw;
      font-family: Monoxil, serif;
      margin-bottom: 3vw;
      margin-top: 3vw;
    }

    h6{
      font-size: 4.5vw;
      margin-bottom: 3vw;
    }

    h2{
      font-size: 8vw;
      margin-top: 0;
    }

    h3{
      font-size: 6vw;
      margin-top: 0;
    }

    img {
      width: calc(100% + 32px) !important;
      max-width: calc(100% + 32px) !important;
      margin-right: calc(var(--ion-padding, 16px) * -1);
      margin-left: calc(var(--ion-padding, 16px) * -1);
    }
  }

  .bg-image {
    min-height: 100vh;
    padding: 0;
    position: relative;
    overflow: hidden;
  }

  @media only screen and (min-width:1024px) {

    .bg-image {
      width: 30vw;
      border-left: 2px solid black;
      border-right: 2px solid black;
    }

    .wrapper {
      padding: 0 !important;

      img {
        width: 100%;
      }
    }

    h1 {
      font-size: 3vw !important;
      width: 26vw;
      padding: 0 !important;

    }

    .text-header {
      font-size: 1.3vw;
      line-height: 2.75vw;
      margin: 0;
      margin-top: 0.625vw;

    }

    .icon-header {
      height: 2vw;
      margin-top: 1vw;
      margin-right: 0.3vw;

    }

    h2{
      font-size: 2vw !important;
      margin-bottom: 5vw;
      margin: 0 !important;
      padding: 0 !important;
      margin-top: 1vw !important;
    }

    h3{
      font-size: 1.5vw !important;
      margin-bottom: 5vw;
      margin: 0 !important;
      padding: 0 !important;
      margin-top: 1vw !important;
    }


    .a-language {
      font-size: 1vw !important;
      line-height: 2.75vw;
      margin-top: 0.625vw;
    }

    .a-social-icon {
      height: 2.75vw;
      width: 2.75vw;
      margin-top: 0.625vw
    }
  }
}

 </style>
